
  import { Component, Watch } from 'vue-property-decorator'
  import { plainToInstance } from 'class-transformer'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixAmountFormatted, fixDate, fixPrice, isValidNumber, parseToNumber, stringifySafe } from '@/utils/general'
  import { Enablement, InspectionQualification } from '@/entities/purchase'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { Debounce } from '@/utils/decorators'
  import { EnablementView } from '@/components/forms/view/EnablementView'
  import IconCell from '@/components/dataTables/cell/IconCell.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { City } from '@/entities/public'
  import { Person } from '@/entities/persons'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import dayjs from 'dayjs'
  import { DateFormatter } from '@/utils/date/DateFormatter'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'

@Component({
  components: { GAlert, GCostField, GRadioButton, GFiles, LinkedPerson, BaseForm, IconCell, AutoResume, StockPhotos },
  methods: { fixAmountFormatted, fixPrice, isValidNumber },
  computed: {},
})
  export default class SupervisorEnablementForm extends EnablementView {
  enablement: Enablement = plainToInstance(Enablement, {})
  message = ''
  processInspectionId = null
  showDetail: Boolean = false
  stock = null
  displayAmount: Boolean = false
  disabledAmount: Boolean = false
  amount = null
  categoryComponentMaintenance = null
  categoryComponentIdentification = null
  metadata = {}
  auto = null
  total = null
  metadataCollection = {}
  cities: City[] = []
  enablementInspectionComponent = []
  processEnablementId = null
  plants: City[] = []
  insurances: Person[] = []
  components = []
  errorMessage = {
    mileage: '',
  }

  title = ''
  mileage = null
  panels = [0]
  section = {
    documents: false,
  }

  radioButtonMessage = {
    bodyWork: '',
    wheels: '',
    inside: '',
    mechanical: '',
    clean: '',
    answerDocuments: '',
    circulationPermit: '',
    soap: '',
    technicalReview: '',
    mileage: '',
    key: '',
  }

  formData = {
    key: {
      status: {
        answer: null,
        provisional: null,
      },
      copy: {
        answer: null,
      },
      cost: undefined,
      photo: [],
      validated: null,
      supervisorCost: null,
      comment: '',
    },
    bodyWork: false,
    bodyWorkCost: null,
    bodyWorkPhoto: [],
    bodyWorkComment: '',
    inspectorBodyWork: false,
    wheels: false,
    wheelsCost: null,
    wheelsPhoto: [],
    wheelsComment: '',
    inspectorWheels: false,
    inside: false,
    insideCost: null,
    insidePhoto: [],
    insideComment: '',
    inspectorInside: false,
    mechanical: false,
    mechanicalCost: null,
    mechanicalPhoto: [],
    mechanicalComment: '',
    inspectorMechanical: false,
    clean: false,
    cleanCost: null,
    cleanPhotoFront: [],
    cleanPhotoRear: [],
    cleanPhotoChassis: [],
    cleanPhotoEngine: [],
    cleanComment: '',
    inspectorClean: false,
    answerDocuments: null,
    answerDocumentsCost: null,
    answerDocumentsComment: '',
    inspectorDocuments: null,
    mileage: {
      photo: [],
      validated: null,
      number: null,
      comment: '',
    },
    soap: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
      inspectionDate: null,
      enablementCost: null,
    },
    soapAnswer: null,
    soapComment: '',
    technicalReview: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
      inspectionDate: null,
      enablementCost: null,
    },
    technicalReviewAnswer: null,
    technicalReviewComment: '',
    circulationPermit: {
      selection: null,
      date: null,
      amount: null,
      photo: null,
      cost: null,
      inspectionDate: null,
      enablementCost: null,
    },
    circulationPermitAnswer: null,
    circulationPermitComment: '',
  }

  fields = {
    mileagePhoto: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    circulationPermit: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    technicalReview: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    soap: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    key: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    bodyWork: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    wheels: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    inside: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    mechanical: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    frontPilot: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    rearPilot: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    chassis: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    engine: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
  }

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()
    const { uid, id, title, metadataCollection } = this
    const enablementId = uid || id

    if (isValidNumber(enablementId)) {
      await this.getEnablementInfo(enablementId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(enablementId))
    }

    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: {},
    })

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { company_type: { name: { _eq: `technical_review_plant` } } },
      limit: 100,
    })

    const companyType = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
    })
    this.insurances = companyType?.[0]?.persons

    this.components = await this.fetchData({
      query: { name: 'find', model: 'InspectionComponent' },
      filter: { _or: [{ component: { category: { name: { _in: ['Documentación', 'Identificación'] } } } }, { component_value: { value: { _eq: 'Llaves' } } }] },
    })

    this.displayDetail()
    this.loadingForm = false
  }

  async setMetadata () {
    const { metadata } = this.getForm('Enablement', 'supervisor_enablement')
    const { form } = metadata as Form
    this.title = form.title
    this.metadataCollection = metadata

    const processInspection = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.processInspectionId = processInspection?.[0]?.id

    this.categoryComponentMaintenance = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Mantenimiento' } },
    })

    this.categoryComponentIdentification = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Identificación' } },
    })

    const processEnablement = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement' } },
    })

    this.processEnablementId = processEnablement?.[0]?.id
  }

  setPropertiesBySlug (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  async setFormDataValues () {
    const fileInfoEnablement = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'enablement' } } },
    })

    this.setPropertiesBySlug(fileInfoEnablement, 'front_pilot', 'frontPilot')
    this.setPropertiesBySlug(fileInfoEnablement, 'rear_pilot', 'rearPilot')
    this.setPropertiesBySlug(fileInfoEnablement, 'chassis', 'chassis')
    this.setPropertiesBySlug(fileInfoEnablement, 'engine', 'engine')
    this.setPropertiesBySlug(fileInfoEnablement, 'bodywork_cost', 'bodyWork')
    this.setPropertiesBySlug(fileInfoEnablement, 'wheels_cost', 'wheels')
    this.setPropertiesBySlug(fileInfoEnablement, 'inside_cost', 'inside')
    this.setPropertiesBySlug(fileInfoEnablement, 'mechanical_cost', 'mechanical')

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.enablement.id } },
          { parameter: { process: { id: { _eq: this.processEnablementId } } } },
        ],
      },
      force: true,
    })

    this.formData.bodyWorkPhoto = files.filter(file => file.parameter.name === 'bodywork_cost')
    this.formData.wheelsPhoto = files.filter(file => file.parameter.name === 'wheels_cost')
    this.formData.insidePhoto = files.filter(file => file.parameter.name === 'inside_cost')
    this.formData.mechanicalPhoto = files.filter(file => file.parameter.name === 'mechanical_cost')
    this.formData.cleanPhotoFront = files.filter(file => file.parameter.name === 'front_pilot')
    this.formData.cleanPhotoRear = files.filter(file => file.parameter.name === 'rear_pilot')
    this.formData.cleanPhotoChassis = files.filter(file => file.parameter.name === 'chassis')
    this.formData.cleanPhotoEngine = files.filter(file => file.parameter.name === 'engine')
  }

  async getEnablementInfo (id) {
    if (!id) return
    this.enablement = await this.fetchData({
      query: { name: 'fetch', model: 'Enablement', params: { id } },
      force: true,
    })
    const stockViewDetails = await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { stock: { id_enablement: { _eq: id } } },
      force: true,
    })
    this.enablement.stock.viewDetails = stockViewDetails[0]
    this.stock = this.enablement.stock
    this.setFormValues(this.enablement)

    const idAuto = this.enablement?.inspectionView[0]?.idAuto || this.enablement?.stock?.viewDetails?.idAuto
    if (idAuto) {
      this.auto = await this.fetchData({
        query: { name: 'fetch', model: 'Auto', params: { id: idAuto } },
        force: true,
      })
    }

    this.enablement.auto = this.auto

    this.enablementInspectionComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: id } },
            { process: { table_name: { _eq: 'enablement' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación', 'Identificación'] } } } } },
                { inspection_component: { component_value: { value: { _eq: 'Llaves' } } } },
              ],
            },
          ],
        },
      },
      force: true,
    })

    await this.setFilesData()
    await this.setFormDataValues()
  }

  setFormValues (enablement) {
    this.formData.inspectorDocuments = enablement.metadata?.inspector.documentation.value
    this.formData.inspectorClean = enablement.metadata?.inspector.clean?.value
    this.formData.inspectorMechanical = enablement.metadata?.inspector.mechanical?.value
    this.formData.inspectorInside = enablement.metadata?.inspector.inside?.value
    this.formData.inspectorWheels = enablement.metadata?.inspector.wheels?.value
    this.formData.inspectorBodyWork = enablement.metadata?.inspector.bodyWork?.value

    this.formData.clean = enablement.metadata?.supervisor?.clean?.value
    this.formData.cleanCost = enablement.metadata?.inspector?.clean?.cost
    this.formData.mechanical = enablement.metadata?.supervisor?.mechanical?.value
    this.formData.mechanicalCost = enablement.metadata?.inspector?.mechanical?.cost
    this.formData.inside = enablement.metadata?.supervisor?.inside?.value
    this.formData.insideCost = enablement.metadata?.inspector?.inside?.cost
    this.formData.wheels = enablement.metadata?.supervisor?.wheels?.value
    this.formData.wheelsCost = enablement.metadata?.inspector?.wheels?.cost
    this.formData.bodyWork = enablement.metadata?.supervisor?.bodyWork?.value
    this.formData.bodyWorkCost = enablement.metadata?.inspector?.bodyWork?.cost
    this.formData.answerDocuments = enablement.metadata?.supervisor?.documentation.value
    this.formData.answerDocumentsCost = enablement.metadata?.inspector?.documentation.cost
  }

  async setFilesData () {
    const { circulationPermit, soap, technicalReview, mileageComponent, key } = this

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'inspection_inspected_component' } } },
    })

    this.setProperties(fileInfo, 'circulationPermit')
    this.setProperties(fileInfo, 'soap')
    this.setProperties(fileInfo, 'technicalReview')
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })

    if (circulationPermit?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(circulationPermit[0].idInspectedComponent, 'circulationPermit', process?.[0]?.id)
    }
    if (soap?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(soap[0].idInspectedComponent, 'soap', process?.[0]?.id)
    }
    if (technicalReview?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(technicalReview[0].idInspectedComponent, 'technicalReview', process?.[0]?.id)
    }

    if (key?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(key[0].idInspectedComponent, 'key', process?.[0]?.id)
    }

    if (mileageComponent?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(mileageComponent[0].idInspectedComponent, 'mileage', process?.[0]?.id)
    }
  }

  async setPhotoToForm (idProcessRecord, fieldKey, idProcess) {
    if (!idProcessRecord) return

    this.formData[fieldKey].photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    if (['mileage'].includes(fieldKey)) {
      return
    }

    const { getInspectionViewByOrder } = this

    if (['key'].includes(fieldKey) && getInspectionViewByOrder(this[fieldKey], 2) instanceof InspectionQualification) {
      this.formData.key.copy.answer = getInspectionViewByOrder(this[fieldKey], 2).assessment.id
      this.formData.key.cost = getInspectionViewByOrder(this[fieldKey], 2).component.cost
      return
    }

    const date = getInspectionViewByOrder(this[fieldKey], 2)?.value

    this.formData[fieldKey].date = DateGenerator.findGeneratedDate(date)?.internal
    this.formData[fieldKey].inspectionDate = DateGenerator.findGeneratedDate(date)?.internal
    if (fieldKey === 'circulationPermit') {
      this.formData[fieldKey].selection = { name: getInspectionViewByOrder(this[fieldKey], 1).value }
      this.formData[fieldKey].amount = getInspectionViewByOrder(this[fieldKey], 3).value
      await this.searchCity(this.formData[fieldKey].selection.name)
    }

    if (fieldKey === 'soap') {
      this.formData[fieldKey].selection = { alias: getInspectionViewByOrder(this[fieldKey], 1).value }
      await this.searchCompany(this.formData[fieldKey].selection.alias)
    }

    if (fieldKey === 'technicalReview') {
      this.formData[fieldKey].selection = { alias: getInspectionViewByOrder(this[fieldKey], 1).value }
      await this.searchPlants(this.formData[fieldKey].selection.alias)
    }
  }

  setProperties (fileInfo, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo[0]
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
    }
  }

  displayDetail () {
    const { enablement, metadataCollection } = this

    this.metadata = {
      data: enablement,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(enablement.id)
  }

  sectionValidation () {
    const keys = ['answerDocuments', 'bodyWork', 'wheels', 'inside', 'mechanical', 'clean', 'mileage', 'key']
    let response = false
    keys.forEach(key => {
      const isAlternativeAnswer = typeof this.formData[key] === 'object' && this.formData[key] !== null && 'validated' in this.formData[key] && this.formData[key].validated === null
      const wrongAnswer = [null, undefined]

      if (wrongAnswer.includes(this.formData[key]) || isAlternativeAnswer) {
        this.radioButtonMessage[key] = 'Campo requerido'
        response = true
      }
    })

    return response
  }

  async send () {
    const { message, errorMessage } = this
    const isSomeError = errorMessage.mileage.length
    if (!this.$refs.form.validate() || message.length || this.sectionValidation() || isSomeError) {
      if (!this.$refs.form.validate() && !this.sectionValidation()) {
        this.alert = {
          open: true,
          title: 'Falta información necesaria para poder avanzar. La habilitación quedará por actualizar para que se complete correctamente.',
        }
      }

      await this.setFieldError()
      return
    }
    this.loadingForm = true

    await this.handleEnablementSupervisor(this)
    if (this.enablement.stock.price) {
      await this.publishStock(this.enablement.stock.id)
    }
    this.loadingForm = false
    await this.close()
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get keyInspection () {
    const { enablement: { inspectionKey } } = this
    return inspectionKey?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')
  }

  get key () {
    const { enablement: { inspectionKey }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')

      if (components.length) {
        return components
      }
    }

    return inspectionKey?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')
  }

  get mileageComponent () {
    const { enablement: { inspectionIdentifiers }, enablementInspectionComponent } = this
    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'mileage')

      if (components.length) {
        return components
      }
    }

    return inspectionIdentifiers?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'mileage')
  }

  get dealAutoAttributesData () {
    const { enablement, processInspectionId } = this

    return { inspection: enablement.inspection, idProcess: processInspectionId }
  }

  @Watch('enablement', { immediate: true, deep: true })
  @Debounce(100)
  async onDealAutoAttributeDataChange (val) {
    if (!val?.id) return

    const filter = {
      _and: [
        { id_process_record: { _eq: val?.idInspection } },
        { process: { table_name: { _eq: 'inspection' } } },
        { component: { slug: { _eq: 'mileage' } } },
      ],
    }

    const attribute = await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter,
      force: true,
    })

    this.mileage = attribute?.[0]
    const filter2 = {
      _and: [
        { id_process_record: { _eq: val?.id } },
        { process: { table_name: { _eq: 'enablement' } } },
        { component: { slug: { _eq: 'mileage' } } },
      ],
    }

    const attributeEnablement = await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: filter2,
      force: true,
    })
    this.formData.mileage.number = attributeEnablement?.[0]?.value
  }

  get circulationPermit () {
    const { enablement: { inspectionDocumentations }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const inspectedComponent = this.getInspectionViewByOrder(enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit'), 1)
      this.formData.circulationPermit.enablementCost = inspectedComponent.component.cost

      return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
    }

    return inspectionDocumentations?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
  }

  get soap () {
    const { enablement: { inspectionDocumentations }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const inspectedComponent = this.getInspectionViewByOrder(enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap'), 1)
      this.formData.soap.enablementCost = inspectedComponent.component.cost
      return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
    }

    return inspectionDocumentations?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
  }

  get technicalReview () {
    const { enablement: { inspectionDocumentations }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const inspectedComponent = this.getInspectionViewByOrder(enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review'), 1)
      this.formData.technicalReview.enablementCost = inspectedComponent.component.cost
      return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
    }

    return inspectionDocumentations?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
  }

  get enablementStatusClosed () {
    const { enablement } = this

    return enablement?.status?.isClosed || enablement.status?.isPending
  }

  get enablementStatusIsNotToConfirm () {
    const { enablement } = this

    return !enablement?.status?.isToConfirm
  }

  @Watch('formData.mileage', { immediate: true, deep: true })
  onMileageValueChange (val) {
    if (!val) return

    if (Number(val) < Number(this.mileage?.value)) {
      this.errorMessage.mileage = 'El kilometraje no puede ser menor al registrado en la inspección'
    } else {
      this.errorMessage.mileage = ''
    }
  }

  async searchCity (val) {
    if (!val) return
    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: { name: { _ilike: `%${val}%` } },
    })
  }

  async searchCompany (val) {
    if (!val) return

    this.insurances = await this.fetchData({
      query: { name: 'find', model: 'Person', order: { alias: 'asc' } },
      filter: { company_type: { name: { _eq: `insurance_carrier` } }, alias: { _ilike: `%${val}%` } },
    })
  }

  async searchPlants (val) {
    if (!val) return

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { _and: [{ company_type: { name: { _eq: `technical_review_plant` } } }, { alias: { _ilike: `%${val}%` } }] },
      limit: 100,
    })
  }

  get isCalculableCost () {
    const { formData } = this

    return {
      amount: formData.circulationPermit.amount,
      date: formData.circulationPermit.date,
    }
  }

  @Watch('isCalculableCost', { immediate: true, deep: true })
  onCalculableCost (val) {
    if (val.amount) {
      this.formData.circulationPermit.cost = Math.round(parseToNumber(val.amount) * this.delayPeriod)
    }
  }

  get delayPeriod () {
    const { circulationPermit, formData } = this

    const date = circulationPermit?.[0]?.date || dayjs()

    return DateGenerator.calculateMonthsDifference(formData.circulationPermit.date, date)
  }

  get expirationCirculation () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.circulationPermit.date) || -1
  }

  get expirationPeriodSoap () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.soap.date)
  }

  get expirationPeriodTechnicalReview () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.technicalReview.date)
  }

  get isTechnicalReviewMoreThanToday () {
    const { formData } = this
    if (!formData?.technicalReview?.date) return false

    return dayjs(fixDate(DateFormatter.toValidDBDate(formData.technicalReview.date)), 'DD/MM/YYYY').isAfter(dayjs())
  }

  @Watch('expirationPeriodSoap', { immediate: true })
  onDateChange (val) {
    if (val >= 1) {
      this.formData.soap.cost = null
    } else {
      this.formData.soap.cost = this.components?.find(ic => ic.component.slug === 'soap')?.config?.cost
    }
  }

  get ppu () {
    const { auto } = this

    return auto?.ppu?.split('')[auto?.ppu.length - 1]
  }

  get technicalDates () {
    const { ppu } = this

    if (!ppu) return []

    return DateGenerator.calculateMonthByDigit(parseToNumber(ppu))
  }

  evaluateDeadline (deadline) {
    if (deadline === null || deadline === undefined) {
      return null
    }
    if (deadline < 0) {
      return 2
    }
    if (deadline === 0) {
      return 1
    }
    if (deadline > 0) {
      return 0
    }
  }

  @Watch('auto', { immediate: true, deep: true })
  async onInspectionChange (val) {
    if (!val?.id) return

    const { generation } = val
    let gen
    if (generation?.id) {
      gen = await this.fetchData({
        query: { name: 'fetch', model: 'Generation', params: { id: generation.id } },
      })
    }
    const { enablement } = this
    const date = enablement.inspectionView?.[0]?.date?.year() || dayjs().year()
    const registration = gen?.registration?.find(registration => parseToNumber(registration.year) === parseToNumber(date))

    this.amount = registration?.permission
    if (!this.formData.circulationPermit.amount) {
      this.formData.circulationPermit.amount = registration?.permission
    }
    this.displayAmount = true
    this.disabledAmount = Boolean(registration?.permission)

    if (!isValidNumber(this.total) && val.metadata?.findCategoryByName('Documentación')) {
      this.total = val.metadata?.findCategoryByName('Documentación')?.total
    }
  }

  get generateDates () {
    const date = dayjs().year()

    return DateGenerator.generateDates(date)
  }

  get generateSoapDates () {
    const date = dayjs().year()

    return DateGenerator.generateDates(date, false)
  }

  get isCirculationPermit () {
    const { circulationPermit } = this

    return circulationPermit?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isSoap () {
    const { soap } = this

    return soap?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isTechnicalReview () {
    const { technicalReview } = this

    return technicalReview?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get documentsAnswers () {
    const { formData: { circulationPermitAnswer, soapAnswer, technicalReviewAnswer } } = this

    return {
      circulationPermitAnswer,
      soapAnswer,
      technicalReviewAnswer,
    }
  }

  get isKeyAnswerLower () {
    const { getInspectionViewByOrder, keyInspection, key } = this

    if (!keyInspection) return false

    const keyInspectionAnswer = parseToNumber(getInspectionViewByOrder(keyInspection, 2)?.assessment.name)
    const keyAnswer = parseToNumber(getInspectionViewByOrder(key, 2)?.assessment.name)

    return keyAnswer < keyInspectionAnswer
  }

  @Watch('documentsAnswers', { immediate: true, deep: true })
  onDocumentsAnswersChange (val) {
    const { circulationPermitAnswer, soapAnswer, technicalReviewAnswer } = val
    this.formData.answerDocuments = circulationPermitAnswer && soapAnswer && technicalReviewAnswer
  }

  @Watch('circulationPermit', { immediate: true, deep: true })
  onCirculationPermitChange (val) {
    if (!val?.length) return
    const { formData } = this

    if (this.getInspectionViewByOrder(val, 1) && this.getInspectionViewByOrder(val, 1)?.validated !== null) {
      formData.circulationPermitAnswer = this.getInspectionViewByOrder(val, 1).validated
    }
  }

  @Watch('soap', { immediate: true, deep: true })
  onSoapChange (val) {
    if (!val?.length) return
    const { formData } = this

    if (this.getInspectionViewByOrder(val, 1) && this.getInspectionViewByOrder(val, 1)?.validated !== null) {
      formData.soapAnswer = this.getInspectionViewByOrder(val, 1).validated
    }
  }

  @Watch('technicalReview', { immediate: true, deep: true })
  onTechnicalReviewChange (val) {
    if (!val?.length) return
    const { formData } = this

    if (this.getInspectionViewByOrder(val, 1) && this.getInspectionViewByOrder(val, 1)?.validated !== null) {
      formData.technicalReviewAnswer = this.getInspectionViewByOrder(val, 1).validated
    }
  }

  @Watch('key', { immediate: true, deep: true })
  async onKeyChange (val) {
    if (!val?.length) return
    const { formData } = this

    const component = val.some(v => v instanceof InspectionQualification) ? val : null

    if (this.getInspectionViewByOrder(component, 2) && this.getInspectionViewByOrder(component, 2)?.validated !== null) {
      const InspectionInspectedComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionInspectedComponent' },
        filter: { inspection_qualifications: { id: { _eq: this.getInspectionViewByOrder(component, 2).id } } },
      })
      if (InspectionInspectedComponent.length) {
        formData.key.supervisorCost = InspectionInspectedComponent[0].supervisorCost
      }
      formData.key.validated = this.getInspectionViewByOrder(val, 2)?.validated
    }
  }

  @Watch('mileageComponent', { immediate: true, deep: true })
  onMileageComponentChange (val) {
    if (!val?.length) return
    const { formData } = this

    const component = val.some(v => v instanceof InspectionQualification) ? val[0] : null

    if (component) {
      formData.mileage.validated = component.validated
    }
  }

  @Watch('formData.answerDocuments', { immediate: true, deep: true })
  onAnswerDocumentsChange (val) {
    if (val) {
      this.formData.answerDocumentsCost = Number(this.formData.soap.enablementCost || 0) + Number(this.formData.technicalReview.enablementCost || 0) + Number(this.formData.circulationPermit.enablementCost || 0)
    } else if (!this.loadingForm) {
      this.formData.answerDocumentsCost = 0
    }
  }
  }
